import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VCard,[_c(VToolbar,{attrs:{"color":"blue lighten-2","cards":"","dark":"","flat":""}},[_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){return _vm.goBack()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" Editar el temps de treball "),_c(VSpacer),_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){return _vm.goMenu()}}},[_c(VIcon,[_vm._v("mdi-exit-to-app")])],1)],1),_c('div',{staticClass:"text-center"},[_c('div',[_c(VCheckbox,{attrs:{"label":"Landscape"},model:{value:(_vm.landscape),callback:function ($$v) {_vm.landscape=$$v},expression:"landscape"}}),_c(VTimePicker,{attrs:{"landscape":_vm.landscape},model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}})],1)]),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"my-3"},[_c(VBtn,{attrs:{"x-large":"","width":"300px","color":"green","dark":""},on:{"click":function($event){return _vm.putCheckInOut()}}},[_vm._v(" Fichar ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }