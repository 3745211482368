//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
export default {
  data: () => ({
    serverHour: 0,
    serverMinute: 0,
    picker: null,
    landscape: false,
  }),
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    goBack() {
      this.$router.push("/mainmenu");
    },
    goMenu() {
      this.$router.push("/mainmenu");
    },
    putCheckInOut() {
      var self = this;
      axios
        .post(api.URL() + "/api/v1/gcheckinout_appendx", {
          token: sessionStorage.getItem("Token"),
          biometricId: "1",
          userId: parseInt(sessionStorage.getItem("UserId")),
          checkType: "0",
          gpsLoc: this.gpsLoc,
        })
        .then(() => {
          self.goMenu();
        })
        .catch(function (error) {
          self.msgAlert(error.response.data, true);
        });
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };

      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
